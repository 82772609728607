@charset "utf-8";

/* topics */
$starting-well-color          : #ca4993 !default;
$living-well-color            : #00a776 !default;
$ageing-well-color            : #9464b3 !default;
$report-color                 : #038cd0 !default;

/* update bubble */
.update--bubble a {
    color: #fff !important;

    &:hover {
      color: #fff !important;
    }
  }

.update--bubble a:visited {
  color: #fff !important;
}

@import "minimal-mistakes/skins/aqua"; // skin
@import "minimal-mistakes"; // main partials

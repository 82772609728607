/* ==========================================================================
   Aqua skin
   ========================================================================== */

/* Colors */
$gray                       : #1976d2 !default;
$dark-gray                  : mix(#000, $gray, 40%) !default;
$darker-gray                : mix(#000, $gray, 60%) !default;
$light-gray                 : mix(#fff, $gray, 50%) !default;
$lighter-gray               : mix(#fff, $gray, 90%) !default;

$body-color                 : #fff !default;
$background-color           : #f0fff0 !default;
$code-background-color      : $lighter-gray !default;
$code-background-color-dark : $light-gray !default;
$text-color                 : $dark-gray !default;
$border-color               : $lighter-gray !default;

$primary-color              : $gray !default;
$success-color              : #27ae60 !default;
$warning-color              : #e67e22 !default;
$danger-color               : #c0392b !default;
$info-color                 : #03a9f4 !default;

/* links */
$link-color                 : $info-color !default;
$link-color-hover           : mix(#000, $link-color, 25%) !default;
$link-color-visited         : mix(#fff, $link-color, 25%) !default;
$masthead-link-color        : $primary-color !default;
$masthead-link-color-hover  : mix(#000, $primary-color, 25%) !default;